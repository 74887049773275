import React from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../assets/img/land.png";
import kano from "../assets/img/kano.png";
import { formatNumber, toCurrency, toNairaWords } from "../utils";
import moment from "moment";

import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import Signature from "../assets/img/mdsign.png";
import { toWordsconver } from "../redux/action/api";
import QRCode from 'qrcode';
// Font.register({ family: 'FamilyName', src: DM_SANS, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });
Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
      // fontWeight: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    // padding: "10px",
    marginHorizontal: 15,
    marginVertical: 15,
    // marginTop:15,
    fontSize: "16px",
    pageBreakInside: "avoid",
    fontWeight: "bold",
  },
  header: {
    textAlign: "center",
    // fontSize: "25px",
    fontWeight: "bolder",
    // marginTop: -40,
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkText: { fontSize: "50px", transform: "rotate(60deg)" },
  itemContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "",
    border: "1px solid #000",
  },
  itemContainer2: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    marginBottom: 7,
  },
  borderBox: {
    border: "1px solid #000",
  },
  qrcodeContainer: {
    textAlign: "center",
    marginTop: "0px",
    marginRight: 10,
    // alignSelf: "center",÷
  },
  signatureContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  qrcodeImage: {
    maxWidth: '120px', // Further reduced QR code image size
    height: '90px', // Further reduced QR code image size
  },
  signatureImage: {
    width: "120px",
    height: "30px",
  },
  textNormal: {
    fontSize: "15px",
    textAlign: "right",
  },
  hederContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // height:"100%",
    // marginTop: 120,
  },
});


const AdminLandReceiptPdf = ({
  data = [],
  user = {},
  qrcode,
  total = 0,
  label = "ORIGINAL",
  name = "",
}) => {
  // 32459029315311

  let canvas;
  canvas = document.createElement('canvas');
  QRCode.toCanvas(
      canvas,
      `https://verify.kirmas.kn.gov.ng/${data[0]?.reference_number}/tax/type/clearance-certificate/invoice/view`.replace(/\|/g, '%7C'),
  );
  const qr = canvas.toDataURL();
  return (
    <View wrap={false} style={styles.page}>
      <View style={styles.hederContainer}>
        <View
          style={{
            width: "40%",
            marginRight: 10,
            marginTop: 100,
          }}
        >
          <View style={styles.itemContainer2}>
            <Text
              style={{
                fontSize: 10.5,
                fontFamily: "DM_SANS",
              }}
            >
              TRANSACTION ID: {data[0]?.reference_number}
            </Text>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                MDA:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.mda_name}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                DEPARTMENT:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {/* {moment().format("YYYY-MM-DD hh:mm:ss A")} */}
                {data[0]?.department}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11 }}>{data[0]?.tax_parent_code}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Payment Status:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.invoice_status}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                PAYMENT VALIDATION:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.logId}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              {data[0]?.mda_val ? (
                <Text
                  style={{
                    fontSize: 10.5,
                    fontFamily: "DM_SANS",
                  }}
                >
                  File No.:
                </Text>
              ) : null}
              {data[0]?.mda_val ? (
                <Text
                  style={{
                    fontSize: 10.5,
                    fontFamily: "DM_SANS",
                  }}
                >
                  {data[0]?.mda_val}
                </Text>
              ) : null}
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {/* AMOUNT: */}
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                  textAlign: "center",
                  marginTop: 15,
                  marginLeft: 50,
                }}
              >
                {formatNumber(total)}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ width: "40%", marginRight: 5, marginTop: 90 }}>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                AMOUNT IN WORDS:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {toWordsconver(total.toString().split(".")[0])?.toUpperCase()}
                NAIRA
                {total.toString().split(".")[1] !== "00"
                  ? ` AND ${toWordsconver(total.toString().split(".")[1])} KOBO`
                  : null}{" "}
                ONLY
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Payment For:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.description}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                PAYMENT METHOD:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.modeOfPayment?.toUpperCase()}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Printed On:{moment().format("YYYY-MM-DD hh:mm:ss A")}
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Date:
                {data[0]?.date_from && data[0]?.date_to
                  ? `${moment(data[0]?.date_from).format(" YYYY")} - ${moment(
                      data[0]?.date_to
                    ).format(" YYYY")}`
                  : moment(data[0]?.transaction_date).format(" YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                FROM
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                  marginLeft: 10,
                }}
              >
                {data[0].tax_payer}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ width: "20%", marginTop: 100, marginLeft: 70 }}>
          <View style={styles.qrcodeContainer}>
            <Image src={qr} style={styles.qrcodeImage} />
          </View>
          <View style={{ alignItems: "left", marginTop: 10, marginLeft: 10 }}>
            <Text
              style={{
                fontSize: 9,
                marginBottom: 1,

                marginTop: 1,
                fontFamily: "DM_SANS",
              }}
            >
              CODE: {data[0]?.item_code}
            </Text>
            <Text
              style={{
                fontSize: 9,
                marginBottom: 1,

                marginTop: 1,
                fontFamily: "DM_SANS",
              }}
            >
              R.V.NO: {"N/A"}
            </Text>
            <Text
              style={{
                fontSize: 9,
                marginBottom: 1,

                marginTop: 1,
                fontFamily: "DM_SANS",
              }}
            >
              SUB CODE: {data[0]?.mda_code}
            </Text>
            {data[0]?.rev_code ? (
              <Text
                style={{
                  fontSize: 9,
                  marginBottom: 1,

                  marginTop: 1,
                  fontFamily: "DM_SANS",
                }}
              >
                ECO. CODE: {data[0]?.rev_code}
              </Text>
            ) : null}
            <Text
              style={{
                fontSize: 9,
                marginBottom: 1,

                marginTop: 1,
                fontFamily: "DM_SANS",
              }}
            >
              ITEM CODE: {data[0]?.item_code}
            </Text>
            <Text
              style={{
                fontSize: 9,
                marginBottom: 1,
                marginTop: 10,
                fontFamily: "DM_SANS",
              }}
            >
              CASHIER: {data[0]?.printed_by || name}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default AdminLandReceiptPdf;
