import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, CardHeader, CardTitle, Col, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import {
    _fetchApi,
    _postApi,
    calculateTax,
    cgiFun,
    chargeableIncome,
    formatData,
    generateCRA,
    getTitle,
    grossIncomeFun,
    taxableIncome,
} from '../../redux/action/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatNumber, formatNumber1, useQuery } from '../../utils';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomModal from '../../components/UI/CustomModal';
import { Image } from '@themesberg/react-bootstrap';
import GeneralCart from '../components/GeneralCart';
import useIsPhoneSize from '../../utils/useIsPhoneSize';

import { CustomButton, CustomForm } from '../../components/UI';
import { Link } from '@material-ui/core';
import { Routes } from '../../routes';
import { IndurialSectors, orgType } from '../../assets/IndurialSectors';
import { Typeahead } from 'react-bootstrap-typeahead';
import SelectInput from '../components/SelectInput';
// import CustomModal from "../../components/UI/CustomModal";

function NominalRollUpload() {
    const { id, assessment_id, taxID } = useParams();
    // const { tax_accounts = [] } = useSelector((val) => val.auth);
    // const { tax_account, user } = useSelector((s) => s.auth);
    // function NominalRollUpload() {
    // const { id, assessment_id, taxID } = useParams();
    const { user, tax_account } = useSelector((s) => s.auth);
    const [loading, setLoading] = useState(false);
    // const [form, setForm] = useState({
    //   rev_code: '',
    //   mda_code: '',
    //   mda_name: '',
    //   taxID: '',
    //   amount: '',
    //   tax_payer: '',
    //   phone: ''
    // });
    const [, setErrorMessage] = useState('');

    const [trx_type, setTrxType] = useState('invoice');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenOrg, setIsOpenOrg] = useState(false);
    const [checkUpdate, setCheckUpdate] = useState(false);
    const toggleOrg = () => setIsOpenOrg(!isOpenOrg);
    const toggle = (type) => {
        setIsOpen(!isOpen);
        if (type) {
            setTrxType(type);
        }
    };
    const isPhoneSize = useIsPhoneSize();
    // const [cols, setCols] = useState([]);
    // const [rows, setRows] = useState([]);
    // const [, setFormattedRows] = useState([]);
    const [, setFinalData] = useState([]);
    // const [submitting, setSubmitting] = useState(false);
    // const [assessment, setAssesment] = useState({});
    // const dispatch = useDispatch()
    let [testData, setTestData] = useState([]);
    // const [numberOfMonths, setNumberOfMonths] = useState(1);
    const [taxHead, setTaxHead] = useState({});

    // const [orgStaffList, setOrgStaffList] = useState([]);
    const query = useQuery();
    // const taxType = query.get('taxType');
    const date_from = query.get('from');
    const date_to = query.get('to');
    const _form = {
        staff_name: '',
        jtb_no: '',
        nin: '',
        staff_id: '',
        phone: '',
        basic_salary: '',
        other_allowance: '',
        nhf: '',
        pension: '',
        life_insurance: '',
        nhis: '',
        gross: '',
        org_kirmas_id: user.taxID,
        query_type: 'insert',
        from: date_from,
        to: date_to,
    };
    const [form, setForm] = useState(_form);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [update, setUpdate] = useState({
        business_sector: '',
        org_type: '',
    });
    let m = moment().format('YYYY-MM');
    const history = useHistory();
    const [date, setDate] = useState(m);
    const [showModal, setShowModal] = useState(false);
    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }));
    };
    useEffect(() => {
        setLoading(true);
        _fetchApi(
            // assessment_id,id
            `/assessments/org-staff-list?org_kirmas_id=${user.taxID}&query_type=select`,
            (resp) => {
                if (resp.success && resp.data.length) {
                    setTestData(resp.data);
                }
                setLoading(false);
            },
            (err) => {
                setTestData([])
                console.error(err);
                toast.error('Errorr occured!');
                setLoading(false);
            },
        );
    }, [user?.taxID]);
    const downloadExcel = () => {
        setLoading(true);
        fetch('https://docs.google.com/spreadsheets/d/1oj-q7C6xcYqqhLnoKoGAtYGoMUdRUhIp/export?format=xlsx')
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'NOMINAL ROLL SAMPLE.xlsx');
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error downloading Excel file: ', error);
                setLoading(false);
            });
    };

    // useEffect(() => {
    //   setLoading(true);
    //   _fetchApi(
    //     // assessment_id,id
    //     `/users/get-pending-tax-view?assessment_id=${assessment_id}&id=${id}`,
    //     (resp) => {
    //       if (resp.success) {
    //         const data = resp.results[0];
    //         console.log(resp.results[0]);
    //         setAssesment(data);
    //         setNumberOfMonths(
    //           moment(data.date_to).diff(moment(data.date_from), "months") + 1
    //         );
    //       }
    //       setLoading(false);
    //     },
    //     (err) => {
    //       console.error(err);
    //       toast.error("Errorr occured!");
    //       setLoading(false);
    //     }
    //   );
    // }, [tax_account?.taxID]);

    const handleSubmit = () => {
        // setLoading(true);
        _postApi(
            '/assessments/org-staffs',
            { list: testData },
            () => {
                setLoading(false);
                // toggle();
                toast.error('Successfully');
            },
            (err) => {
                console.log(err);
                setLoading(false);
                toast.error('Error Occured', err);
            },
        );
    };

    useEffect(() => {
        if (user?.taxID) {
            if (!orgType?.includes(tax_account?.org_type)) {
                toggleOrg();
            } else {
                _fetchApi(`/users/get-code?type=${tax_account?.org_type}`, (resp) => {
                    if (resp.success) {
                        setTaxHead(resp.data[0]);
                    }
                });
            }
        }
    }, [user?.taxID]);

    const handleUpload = (event) => {
        let fileObj = event.target.files[0];
        if (!fileObj) {
            setErrorMessage('No file uploaded!');
            return false;
        }
        console.log('fileObj.type:', fileObj.type);
        if (
            !(
                fileObj.type === 'application/vnd.ms-excel' ||
                fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        ) {
            console.log('just pass the fileObj as parameter');
            setErrorMessage('Unknown file format. Only Excel files are uploaded!');
            return false;
        }
        //just pass the fileObj as parameter
        console.log('just pass the fileObj as parameter');
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                let newRows = [];
                let data = [];
                // let basicInfo = []
                // let beginIndex = 0
                // let detailInfo = []
                // let items = []

                let colNames = resp.rows[6];

                resp.rows.slice(6).forEach((row, index) => {
                    if (row && row !== 'undefined' && row.length !== 0) {
                        console.log(row);
                        let obj = {};
                        colNames.forEach((col, idx) => {
                            obj[col] = row[idx];
                        });
                        data.push(obj);
                        newRows.push({
                            key: index,
                            jtb_no: row[0],
                            nin_no: row[1],
                            phone: row[2],
                            staff_id: row[3],
                            staff_name: row[4],
                            org_name: tax_account?.org_name || tax_account?.name,
                            org_id: tax_account?.taxID,
                            nin: row[0],
                            basic_salary: row[5],
                            other_allowances: row[6],
                            nhf: row[7],
                            nhis: row[8],
                            pension: row[9],
                            query_type: 'insert',
                            gross: grossIncomeFun(row[6], row[5]),
                            org_name: tax_account?.org_name || tax_account?.name,
                            org_kirmas_id: tax_account?.taxID,
                            cgi: cgiFun(grossIncomeFun(row[6], row[5]), row[9], row[7], row[8]),
                            cra: generateCRA(
                                grossIncomeFun(row[6], row[5]),
                                cgiFun(grossIncomeFun(row[6], row[5]), row[9], row[7], row[8]),
                            ),
                            taxable_income: taxableIncome(row[9], row[7], row[8], row[6], row[5]),
                            payable: calculateTax(taxableIncome(row[9], row[7], row[8], row[6], row[5])),
                        });
                    }
                });
                console.log(newRows);
                setTestData(newRows);
                if (newRows.length === 0) {
                    setErrorMessage('No data found in file!');
                    return false;
                } else {
                    // setCols(resp.cols);
                    // setRows(resp.rows);
                    // setFormattedRows(newRows);
                    let ff = formatData(newRows);
                    setFinalData(ff);
                    setErrorMessage(null);

                    return;
                }
            }
        });
        return false;
    };
    const calculatePaye = (payable) => {
        let numberOfMonths = moment(form.to).diff(moment(form.from), 'months') + 1;
        let paye = numberOfMonths * (payable / 12);
        return paye;
    };
    const _form_fields = [
        {
            name: 'staff_id',
            label: 'Staff ID',
            value: form.staff_id,
        },
        {
            name: 'staff_name',
            label: 'Staff name',
            value: form.staff_name,
        },

        {
            name: 'taxID',
            label: 'KIRMAS ID.',
            value: form.taxID,
        },
        {
            name: 'phone',
            label: 'Phone No.',
            value: form.phone,
        },
        {
            name: 'nin',
            label: 'NIN',
            value: form.nin,
        },
        {
            name: 'jtb_no',
            label: 'JTB No.',
            value: form.jtb_no,
        },
        {
            name: 'basic_salary',
            label: 'Basic Salary',
            value: form.basic_salary,
        },
        {
            name: 'pension',
            label: 'Pension',
            value: form.pension,
        },
        {
            name: 'other_allowance',
            label: 'Other allowances',
            value: form.other_allowance,
        },
        {
            name: 'nhf',
            label: 'NHF',
            value: form.nhf,
        },
        {
            name: 'nhis',
            label: 'NHIS',
            value: form.nhis,
        },

        {
            name: 'gross',
            label: 'Gross Salary',
            value: form.gross,
        },
    ];
    const handleAdd = () => {
        _postApi(
            '/assessments/org-staffs',
            { list: [form] },
            (resp) => {
                if (resp.success) {
                    setLoading(false);
                    toast.success('Done');
                }
            },
            (err) => {
                console.log(err);
                setLoading(false);
                toast.error('Error Occured', err);
            },
        );
    };

    const handleUpdate = () => {
        if (update.org_type === '' || update.business_sector === '') {
            toast.error('Please complete the form');
        } else {
            setUpdateLoading(true);
            _fetchApi(
                `/users/update-sec?org_type=${update.org_type}&business_sector=${update.business_sector}&taxID=${user.taxID}`,
                (resp) => {
                    if (resp.success) {
                        toast.success('All set! You can now click to generate the invoice or proceed to payment.');
                        _fetchApi(`/users/get-code?type=${tax_account?.org_type}`, (resp) => {
                            if (resp.success) {
                                setTaxHead(resp.data[0]);
                                toggleOrg();
                            }
                        });
                    }
                    setUpdateLoading(false);
                },
                (err) => {
                    console.log(err);

                    toast.error('Error Occured', err);
                    setUpdateLoading(false);
                },
            );
        }
    };

    useEffect(() => {
        const startDate = moment(date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        const endDate = moment(date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
        setForm((p) => ({ ...p, from: startDate, to: endDate }));
    }, [date]);
    return (
        <Card>
            {loading ? (
                <center>
                    <Spinner />
                </center>
            ) : (
                <>
                    {/* {JSON.stringify(taxHead)} */}

                    <CardHeader className="">
                        <h4 className="mt-2 text-center">PAYE SCHEDULE</h4>
                        <h4 className="text-center">
                            {tax_account?.org_name?.toUpperCase() || tax_account?.name?.toUpperCase()}(
                            {tax_account?.taxID})
                        </h4>
                        {testData.length ? (
                            <h4 className="text-center">
                                Period :{moment(form.from).format('YYYY-MM-DD')} to{' '}
                                {moment(form.to).format('YYYY-MM-DD')}
                            </h4>
                        ) : null}
                    </CardHeader>
                    {/* <Row className="text-center">
                        {!testData.length ? (
                        <Col className=" mt-2">
                            <label>
                                Click here to upload a file
                                <input
                                    accept=".xlsx,.xls"
                                    type="file"
                                    onChange={handleUpload}
                                    style={{ padding: '10px' }}
                                />
                            </label>
                        </Col>
                        ) : null}
                        <Col className="mt-3 text-right m-1">
                            {testData.length ? (
                                <div className="">
                                    <CustomButton size="sm" className="m-1" onClick={() => setShowModal(true)}>
                                        Add Staff
                                    </CustomButton>
                                   

                                 
                                </div>
                            ) : null}
                            <CustomButton size="sm" onClick={handleSubmit}>
                                Click here to Upload Schedule
                            </CustomButton>
                        </Col>
                        <Col className="mt-3 text-right m-1">
                            <Button onClick={downloadExcel} size="sm">
                                {loading ? 'loading...' : 'Download Excel Template'}
                            </Button>
                        </Col>
                        {!testData.length ? (
                            <Col className="mt-3 text-right m-1">
                                <Button onClick={downloadExcel} size="sm">
                                    {loading ? 'loading...' : 'Download Excel Template'}
                                </Button>
                            </Col>
                        ) : null}
                    </Row> */}

                    {testData.length ? (
                        <>
                            <Row>
                                <Col className="m-2  text-">
                                    <b className="text-left">Total No.of Staff: {formatNumber1(testData.filter(x=>x.status==='active')?.length)}</b>
                                </Col>
                                <Col className="m-2 ">
                                    <b>
                                        Total PAYE Per Annum: ₦
                                        {formatNumber(testData.filter(x=>x.status==='active')?.reduce((it, id) => it + parseFloat(id.payable), 0))}
                                    </b>
                                </Col>
                                <Col className="m-2  ml-4">
                                    <p className="b">
                                        <b>
                                            Total Monthly PAYE: ₦
                                            {formatNumber(
                                                calculatePaye(
                                                    testData.filter(x=>x.status==='active')?.reduce((it, id) => it + parseFloat(id.payable), 0),
                                                ),
                                            )}
                                        </b>
                                    </p>

                                    <p>
                                        {/* <RaisePaye
                      taxID={user.taxID}
                      paye_type={tax_account.org_type}
                      amount={calculatePaye(
                        testData.reduce(
                          (it, id) => it + parseFloat(id.payable),
                          0
                        )
                      )} /> */}
                                    </p>
                                </Col>
                            </Row>
                            {/* {JSON.stringify(testData)} */}
                            <Row className="m-1">
                                <div className="col-md-9 mr-4 pr-4 mb-1 ml-3">
                                    <Label>Select Month to file</Label>
                                    <Input
                                        type="month"
                                        value={date}
                                        name="date"
                                        onChange={({ target: { value } }) => {
                                            const startDate = moment(value, 'YYYY-MM')
                                                .startOf('month')
                                                .format('YYYY-MM-DD');
                                            const endDate = moment(value, 'YYYY-MM')
                                                .endOf('month')
                                                .format('YYYY-MM-DD');
                                            setForm((p) => ({ ...p, from: startDate, to: endDate }));
                                            setDate(value);
                                        }}
                                    />
                                </div>
                                {/* {JSON.stringify(tax_account)} */}
                                <div className="col-md-3 mt-4">
                                    <Button
                                        size="sm"
                                        variant="dark"
                                        className="m-1"
                                        onClick={() => {
                                            if (!orgType?.includes(tax_account?.org_type)) {
                                                toggleOrg();
                                            } else {
                                                setForm((p) => ({
                                                    ...p,
                                                    ...taxHead,
                                                    amount: formatNumber(
                                                        calculatePaye(
                                                            testData.reduce((it, id) => it + parseFloat(id.payable), 0),
                                                        ),
                                                    ),
                                                    total: formatNumber(
                                                        calculatePaye(
                                                            testData.reduce((it, id) => it + parseFloat(id.payable), 0),
                                                        ),
                                                    ),
                                                    page_title: 'TAX PAYMENTS',
                                                    trx_type: 'invoice',
                                                    checked: true,
                                                }));
                                                setIsOpen(true);
                                            }
                                        }}
                                    >
                                        Generate Invoice
                                    </Button>
                                    <Button
                                        size="sm"
                                        className="m-1"

                                        onClick={() => {
                                            if (!orgType?.includes(tax_account?.org_type)) {
                                                toggleOrg();
                                            } else {
                                                setForm((p) => ({
                                                    ...p,
                                                    ...taxHead,
                                                    amount: formatNumber(
                                                        calculatePaye(
                                                            testData.reduce((it, id) => it + parseFloat(id.payable), 0),
                                                        ),
                                                    ),
                                                    total: formatNumber(
                                                        calculatePaye(
                                                            testData.reduce((it, id) => it + parseFloat(id.payable), 0),
                                                        ),
                                                    ),
                                                    page_title: 'TAX PAYMENTS',
                                                    trx_type: 'payment',
                                                    checked: true,
                                                }));
                                                setIsOpen(true);
                                            }
                                        }}
                                    >
                                        Pay now
                                    </Button>
                                </div>
                            </Row>
                            <Table bordered striped responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center">S/N</th>
                                        <th className="text-center">JTB NO.</th>
                                        <th className="text-center">NIN NO.</th>
                                        <th className="text-center">Phone NO.</th>
                                        <th className="text-center">Staff Name</th>
                                        <th className="text-center">Basic Salary(Month)</th>
                                        <th className="text-center">Other Allowances(Annual)</th>
                                        <th className="text-center">NHF(Annual 2.5%)</th>
                                        <th className="text-center">NHIS(Annual 5%)</th>
                                        <th className="text-center">PENSION(Annual 8%)</th>
                                        <th className="text-center">Gross Income</th>
                                        <th className="text-center">CGI(Annual)</th>
                                        <th className="text-center">CRA(Annual)</th>
                                        <th className="text-center">Taxable Income(Annual)</th>
                                        <th className="text-center">Annual Paye</th>
                                        <th className="text-center">Monthly Paye</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {testData?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{item.jtb_no || item.tin}</td>
                                            <td className="text-center">{item.nin_no || item.nin}</td>
                                            <td className="text-center">{item.phone}</td>
                                            <td className="">{item.staff_name || item.name}</td>

                                            <td className="text-right text-end">{formatNumber(item.basic_salary)}</td>
                                            <td className="text-right text-end">
                                                {formatNumber(item.other_allowances)}
                                            </td>

                                            <td className="text-right text-end">{formatNumber(item.nhf)}</td>
                                            <td className="text-right text-end">{formatNumber(item.nhis)}</td>
                                            <td className="text-center">{formatNumber(item.pension)}</td>
                                            <td className="text-right text-end">
                                                {formatNumber(item.gross || item.gross_salary)}
                                            </td>
                                            <td className="text-right text-end">{formatNumber(item.cgi)}</td>
                                            <td className="text-right text-end">{formatNumber(item.cra)}</td>
                                            <td className="text-right text-end">{formatNumber(item.taxable_income)}</td>
                                            <td className="text-right text-end">{formatNumber(item.payable)}</td>
                                            <td className="text-right text-end">{formatNumber(item.payable / 12)}</td>
                                            <td className="text-right text-end"><Badge color={item.status==='active'?'info':'danger'}>{item.status}</Badge></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <div>
                            <Alert className="text-center">
                                No staff uploaded yet.{' '}
                                <Link
                                    onClick={() => {
                                        history.push(Routes.PayeSetup.path);
                                    }}
                                >
                                    Click here to upload the nominal roll
                                </Link>
                            </Alert>
                        </div>
                    )}
                    <CustomModal size="lg" toggle={toggleOrg} logo={<h4>Update User Info</h4>} isOpen={isOpenOrg}>
                        <Col md={12} className="mb-1">
                            <SelectInput
                                label={
                                    <>
                                        Type of Organizaion
                                        {/* <span style={{ color: 'red' }}> *</span> */}
                                    </>
                                }
                                required={form.account_type === 'org' ? true : false}
                                type="text"
                                name="org_type"
                                // required={true}
                                options={orgType?.map((item) => item)}
                                value={form.org_type}
                                onChange={({ target: { name, value } }) => {
                                    setUpdate((p) => ({
                                        ...p,
                                        [name]: value,
                                    }));
                                }}
                            />
                        </Col>
                        <Col md={12} className="mb-1">
                            <Label>Business Sector</Label>
                            <Typeahead
                                id="bus"
                                placeholder="Select Business Sector"
                                type="text"
                                name="business_sector"
                                options={IndurialSectors}
                                value={form.occupation}
                                onChange={(selected) => {
                                    setUpdate((p) => ({
                                        ...p,
                                        business_sector: selected,
                                    }));
                                }}
                            />
                        </Col>
                        <div className="mt-2 text-center">
                            <CustomButton loading={updateLoading} onClick={handleUpdate}>
                                Update
                            </CustomButton>
                        </div>
                    </CustomModal>
                    {/* {JSON.stringify(testData)} */}
                    <CustomModal
                        logo={
                            <center className="p-0 text-center">
                                <Image
                                    src={require('../../assets/img/kigra.jpg')}
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{
                                        borderRadius: '64px',
                                        height: isPhoneSize ? '10%' : '20%',
                                        width: isPhoneSize ? '10%' : '20%',
                                    }}
                                />
                            </center>
                        }
                        size="lg"
                        toggle={toggle}
                        isOpen={isOpen}
                    >
                        {/* {JSON.stringify(selected_taxes)} */}
                        <GeneralCart
                            form={form}
                            data={[
                                {
                                    ...taxHead,
                                    tax_parent_code: taxHead?.title,
                                    amount: formatNumber(
                                        calculatePaye(testData.reduce((it, id) => it + parseFloat(id.payable), 0)),
                                    ),
                                    tax_fee: calculatePaye(testData.reduce((it, id) => it + parseFloat(id.payable), 0)),
                                    description: taxHead?.title,
                                    transaction_type: 'invoice',
                                    service_category: taxHead?.tax_parent_code,
                                    page_title: 'TAX PAYMENTS',
                                    trx_type: 'invoice',
                                    checked: true,
                                    from: form.from,
                                    to: form.to,
                                },
                            ]}
                            with_staff={true}
                            setModal={setIsOpen}
                            handleTaxFeeChecked={() => { }}
                        />
                    </CustomModal>
                </>
            )}
            <CustomModal
                size="lg"
                toggle={() => {
                    setShowModal(!showModal);
                }}
                isOpen={showModal}
            >
                <h4>Add New Staff</h4>
                <CustomForm fields={_form_fields} handleChange={handleChange} />
                <center>
                    {' '}
                    <CustomButton onChange={handleAdd}>Submit</CustomButton>
                </center>
            </CustomModal>
        </Card>
    );
}

export default NominalRollUpload;
