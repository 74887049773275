import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts'

const colors = ['#F2BA4B', '#5887B8']

function BarChartComponent({ data = [], bars = [] }) {
    // Custom tick formatter function
// Custom tick formatter function
const formatYTick = (value) => {
    if (value >= 1000000000000) { // 1 trillion or more
        return (value / 1000000000000).toFixed(1) + 'T';
    } else if (value >= 1000000000) { // 1 billion to less than 1 trillion
        return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) { // 1 million to less than 1 billion
        return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) { // 1 thousand to less than 1 million
        return (value / 1000).toFixed(1) + 'K';
    }
    return value.toFixed(1); // Less than 1 thousand
};

    const formatXTick = (value) => {
        const maxLength = 7
        if (value.length > maxLength) {
            return value.substring(0, maxLength) + '...'
        }
        return value
    }
// Function to determine the max Y-axis value based on the highest data value
const calculateMaxYValue = () => {
    const maxValue = Math.max(...data.flatMap((d) => bars.map((b) => d[b.title])));

    // Define intervals in increasing order for scaling
    const intervals = [10000000, 100000000, 500000000, 1000000000, 5000000000, 10000000000];

    // Find the first interval that is greater than the maxValue
    for (let i = 0; i < intervals.length; i++) {
        if (maxValue <= intervals[i]) {
            return intervals[i];
        }
    }

    // If the maxValue exceeds the largest interval, calculate the next power of 10 or scaled value
    const magnitude = Math.pow(10, Math.ceil(Math.log10(maxValue)));
    
    // Adjust for the next logical step (like 5x steps)
    if (maxValue < 5 * magnitude) return 5 * magnitude;
    return magnitude;
};


    const maxYValue = calculateMaxYValue()

    return (
        <div className="bg-light py-2">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="1 10" />
                <XAxis dataKey="sector" tickFormatter={formatXTick} />
                <YAxis tickFormatter={formatYTick} domain={[0, maxYValue]} />
                <Tooltip formatter={formatYTick} />
                <Legend />
                {bars.map((b, i) => {
                    if (b.hide) return null
                    return (
                        <Bar key={b.title} dataKey={b.title} fill={colors[i]} />
                    )
                })}
            </BarChart>
        </div>
    )
}

export default BarChartComponent
