import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Input,
    Label,
    Row,
    Spinner,
    Table,
} from "reactstrap";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { _fetchApi } from "../../redux/action/api";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import { formatNumber } from "../../utils";
import toast from "react-hot-toast";
import CustomModal from "../../components/UI/CustomModal";
import { Image, Nav, NavItem } from "@themesberg/react-bootstrap";
// import GeneralCart from "../components/GeneralCart";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { TAX_ACCOUNT } from "../../redux/action/type";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faPlus, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import NewCart from "../components/NewCart";
import PaymentsContainer from "./components/PaymentsContainer";
import { faAlgolia } from "@fortawesome/free-brands-svg-icons";
import { Routes } from "../../routes";
import PaymentsContainerNew from "./components/PaymentsContainerNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function MDARevenue() {
    const _form = {
        page_title: "MDAs CHARGE, FEES AND LEVEIS",
        type: "",
        month: "",
        org: "",
        amount: "",
        attachment: "",
        sector: "NON TAX",
        tax_id: "",
        rows: [],
        tax_code: "",
        tax_parent_code: "",
        taxes: "",
        selected: [],
        isLastParent: false,
        from: "",
        to: "",
        phone: "",
        invoice_status: "Full payment",
    };

    const [form, setForm] = useState(_form);
    const [MDAList, setMDAList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [selectedTaxes, setSelectedTaxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currTax, setCurrTax] = useState({});
    const [showDefault, setShowDefault] = useState(false);
    const toggleModal = () => setShowDefault(!showDefault);

  const [tax_payers, setTaxPayers] = useState([]);
    const [tax_payer, setTapayer] = useState("");
    const isPhoneSize = useIsPhoneSize();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const { tax_account, tax_accounts = [], user } = useSelector((p) => p.auth);
    const toggle = (type) => {
        setIsOpen(!isOpen);
    };
    const myRef = useRef()
    const getTaxPayers = useCallback(() => {
        if (tax_payer.length > 2) {
          _fetchApi(
            `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
            (resp) => {
              if (resp.success) {
                setTaxPayers(resp.data);
              }
            }
          );
        } else if (tax_payer.length === 0) {
          _fetchApi(
            `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
            (resp) => {
              if (resp.success) {
                setTaxPayers(resp.data);
              }
            }
          );
        }
      }, [tax_payer]);
    
      useEffect(() => {
        getTaxPayers();
      }, [getTaxPayers]);
    const getList2 = useCallback(() => {
        if (form.mda_name) {
            setLoading(true);
            _fetchApi(
                `/kigra-taxes?query_type=plat-mda-taxes&mda_name=${form.mda_name}`,
                (resp) => {
                    if (resp.result) {
                        setTaxList(resp.result);
                        setLoading(false);
                    }
                },
                (err) => {
                    setTaxList([]);
                    console.error(err);
                    setLoading(false);
                }
            );
        }
    }, [form.mda_name]);

    useEffect(() => {
        getList2();
    }, [getList2]);

    useEffect(() => {
        _fetchApi(
            `/kigra-taxes?query_type=mda-list&sector=${"NON TAX"}`,
            (resp) => {
                if (resp.result) {
                    setMDAList(resp.result);
                    setTaxList([]);
                    setLoading(false);
                }
            },
            (err) => {
                console.error(err);
                setTaxList([]);
                setMDAList([]);
                setLoading(false);
            }
        );
    }, []);

    const handleDateRangeChange = ({ target: { name, value } }) => {
        if (name === "from") {
            setForm((p) => ({ ...p, from: value }));
        } else if (name === "to") {
            setForm((p) => ({ ...p, to: value }));
        }
    };

    const handleChange = ({ target: { name, value } }) => {
        setForm((s) => ({ ...s, [name]: value }));
    };

    const handleTaxFeeChecked = () => {
        // Validate form fields
        if (!form.mda_name || !form.tax_fee || !form.from || !form.to) {
            toast.error("Please fill all fields before adding.");
            return;
        }
        setCurrTax({})
        getList2()
        // Add current form data to the selectedTaxes array
        setSelectedTaxes((prev) => [...prev, {...form, checked: true}]);

        // Reset the form fields to initial state
        setForm(_form);
    };

    const proceed = () => {
       return form.from && form.to && form.mda_name && parseFloat(form.tax_fee)>0 && form.title
    };

    const handleFocus = (name) => {
        setTapayer(name);
        setTimeout(() => {
          toast.success("You can now close and search the tax payer by name or ID");
          toggleModal();
        }, 2000);
      };
    
//   const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);
      const history = useHistory()
    return (
        <PaymentsContainerNew
    //   handleAddList={handleAddList}
      open={open}
      openModal={openModal}
    //   form={form}
    menu={<div style={{
        marginLeft:'10px',
        textAlign:'left'

    }}>
           <br/>   <br/>
        <Button className="p-2 text-left" size='sm'
         onClick={()=>history.push(Routes.TaxPayment.path)}  block> <FontAwesomeIcon icon={faCreditCard} /> Tax Revenue</Button>
        <br/>
        <Button className="p-2 text-left" 
        onClick={()=>history.push(Routes.UserProfile.path)}  
        size='sm' block> <FontAwesomeIcon icon={faUserAlt} /> Profile</Button>
    </div>}
      >  <div className="p-3">
                <h4 className="text-center">{form.page_title}</h4>
               
            <hr />
            {/* {JSON.stringify(form)} */}
            <div className="">
                <h3>{loading && <Spinner />}</h3>

                <Form onSubmit={toggle}>
                    <DaterangeSelector from={form.from} to={form.to} handleChange={handleDateRangeChange} />
                    <Row>
                    <Col md={12}>
                      {user.role !== "user" ? (
                        <Row>
                          <Col md={6} className="mb-2">
                            <CustomTypeahead
                              onInputChange={(e) => {
                                setTapayer(e);
                                console.log(e, "TYPED");
                              }}
                              _ref={myRef}
                              labelKey={(e) =>
                                `${e.account_type === "org" ? e.org_name : e.name
                                }(${e.taxID}) ${e.phone ? `:${e.phone}` : ""}`
                              }
                              onChange={(e) => {
                                if (e && e[0]?.id) {
                                  dispatch({
                                    type: TAX_ACCOUNT,
                                    payload: e[0],
                                  });
                                }
                              }}
                              // onInputChange={(e) => {
                              //   setTapayer(e);
                              // }}
                              clearButton
                              col={12}
                              label={`Select Tax payer`}
                              options={tax_payers}
                              placeholder="Search Name | TaxID | Phone No."
                            />
                          </Col>
                          <Col>
                            <br />
                            <Button
                              onClick={() => toggleModal()}
                              className="bg-white mt-2 ml--2 text-dark"
                            >
                              <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <Label>Tax Payer </Label>{" "}
                            <Input
                              type="text"
                              disabled="disable"
                              value={
                                tax_account.account_type === "org"
                                  ? tax_account.org_name
                                  : tax_account.name
                              }
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12}>
                            <Label>Select MDA</Label>
                            <CustomTypeahead
                                labelKey={(e) => `${e.mda_name}`}
                                onInputChange={()=>{
                                    setForm((s) => ({ ...s,mda_name: '' }));
                                }}
                                onChange={(e) => {
                                    // alert(JSON.stringify(e[0]))
                                    if (e && e?.[0]?.mda_name) {
                                        setForm((s) => ({ ...s,mda_name: e[0]?.mda_name }));
                                    }
                                }}
                                clearButton
                                col={12}
                                selected={form.mda_name ? [{ mda_code: form.mda_code, mda_name: form.mda_name }] : null}
                                options={MDAList}
                                placeholder="Search MDA by Name"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12}>
                            <Label>Select Revenue Description</Label>
                            <CustomTypeahead
                                labelKey={(e) => `${e.title}`}
                                onInputChange={()=>{
                                    setForm((s) => ({ ...s,tax_code: '', title:'', economic_code:'' }));
                                    setCurrTax({})
                                }}
                                onChange={(e) => {
                                    if (e && e[0]?.title) {
                                        setForm((p) => ({ ...p, ...e[0] }));
                                        setCurrTax(e[0])
                                    }
                                }}
                                clearButton
                                col={12}
                                options={taxList}
                                selected={currTax.id ? [currTax] : null}
                                value={currTax.id ? [currTax] : null}
                                placeholder="Search Revenue Description"
                            />
                        </Col>
                        <Col className="" md={4}>
                            <Label>Amount</Label>
                            <Input
                                placeholder={currTax.tax_fee && currTax.tax_fee !== '0.00' ? "Enter Amount" : currTax.tax_fee}
                                type="number"
                                value={form.tax_fee}
                                name={"tax_fee"}
                                onChange={handleChange}
                                disabled={currTax.tax_fee && currTax.tax_fee !== '0.00'}
                            />
                        </Col>
                        {/* {proceed()?<Col className="pt-3 mt-0 text-center">
                            <Button color="primary" onClick={handleTaxFeeChecked}>
                                Add more items
                            </Button>
                        </Col>:null} */}
                    </Row>
                </Form>

                {/* Table to render selectedTaxes */}
               {selectedTaxes.length ? <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>MDA Name</th>
                            <th>Tax Description</th>
                            <th>Amount</th>
                            <th>From</th>
                            <th>To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedTaxes.length > 0 ? (
                            selectedTaxes.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.mda_name}</td>
                                    <td>{item.title}</td>
                                    <td>{formatNumber(item.tax_fee)}</td>
                                    <td>{item.from}</td>
                                    <td>{item.to}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    No items added yet.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>:null}
                {(proceed() || selectedTaxes.length ) ?<Col md={12} className="pt-3 mt-0 text-center">
                    <Button color="warning" onClick={toggle}>
                        Proceed to Payment
                    </Button>
                </Col>:null}
            </div>

            <CustomModal
                logo={
                    <center className="p-0 text-center">
                        <Image
                            src={require("../../assets/img/kigra.jpg")}
                            className="img-fluid"
                            alt="Logo"
                            style={{
                                borderRadius: "64px",
                                height: isPhoneSize ? "10%" : "20%",
                                width: isPhoneSize ? "10%" : "20%",
                            }}
                        />
                    </center>
                }
                size="lg"
                toggle={toggle}
                isOpen={isOpen}
            >
                {/* {JSON.stringify(selectedTaxes)} */}
                <NewCart
                    form={{ ...form }}
                    data={selectedTaxes.length ? selectedTaxes : [{ ...form, checked: true }]}
                    setModal={setIsOpen}
                    handleTaxFeeChecked={() => { }}
                />
            </CustomModal>
        </div>
        </PaymentsContainerNew>
       
    );
}
