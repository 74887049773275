import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Input, Spinner, Table } from "reactstrap";
import { SearchBar, SelectInput } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faPrint,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Col,
  Row,
  ButtonGroup,
  Card,
} from "@themesberg/react-bootstrap";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import UserTransactions from "./UserTransactions";
import { formatNumber1, toParagraph } from "../utils";
import { CounterWidget } from "../components/Widgets";
import { IndurialSectors, orgType } from "../assets/IndurialSectors";
import { ModalTitle } from "react-bootstrap";

function TaxPayer() {
  const _form = {
    limit: 100,
    offset: 0,
    org_type: "",
    business_sector: "",
  };

  // const updateTaxPayer = () => {
  //     if (!taxPayer) return;
  //     _postApi(
  //         '/auths/-post-users',
  //         {
  //             query_type: 'update-taxpayer',
  //             tax_payer_id: taxPayer.taxID,
  //             name: editedTaxPayer.name,
  //             phone: editedTaxPayer.phone,
  //             email: editedTaxPayer.email,
  //             address: editedTaxPayer.address,
  //             org_name: editedTaxPayer.org_name,

  //         },
  //         (res) => {
  //             console.log('Tax payer updated successfully', res);
  //             setShow(false);
  //             getData();
  //         },
  //         (error) => {
  //             console.log('Error updating tax payer', error);
  //         }
  //     );
  // };

  const updateTaxPayer = () => {
    console.log("taxPayer:", taxPayer);
    if (!taxPayer) {
      console.error("Tax payer is not defined");
      return;
    }

    _postApi(
      "/auths/-post-users",
      {
        query_type: "update-taxpayer",
        tax_payer_id: taxPayer.taxID,
        taxID: editedTaxPayer.taxID,
        name: editedTaxPayer.name,
        phone: editedTaxPayer.phone,
        email: editedTaxPayer.email,
        address: editedTaxPayer.address,
        org_name: editedTaxPayer.org_name,
      },
      (res) => {
        console.log("Tax payer updated successfully", res);
        setShow2(false);
        getData();
      },
      (error) => {
        console.log("Error updating tax payer", error);
      }
    );
  };

  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  // const [taxPayer, setTaxPayer] = useState(null);
  const [taxPayer, setTaxPayer] = useState({});
  const [filterText, setFilterText] = useState("");
  const [type, setType] = useState("individual");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [orgCategories, setOrgCategories] = useState([]);
  const getData = useCallback(() => {
    setLoading(true);
    _postApi(
      "/auths/-post-users",
      {
        limit: form.limit,
        offset: form.offset,
        account_type: type,
        query_type: "select-tax-payers",
        business_sector: form.business_sector,
        org_type: form.org_type,
      },
      (res) => {
        if (res.success && res.data.length) {
          setLoading(false);
          setData(res.data);
          setTotalItems(res.data[0].total_rows);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
    _fetchApi(`/users/get-org-types`, (resp) => {
      if (resp.success) {
        setOrgCategories(resp.data);
      }
    });
  }, [form, type]);

  useEffect(() => {
    getData();
  }, [getData]);

  const [editedTaxPayer, setEditedTaxPayer] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    org_name: "",
    // org_type: '',
  });

  useEffect(() => {
    if (filterText.length > 3) {
      _postApi(
        "/auths/-post-users",
        {
          limit: form.limit,
          offset: form.offset,
          query_type: "select-tax-payer",
          account_type: type,
          user_id: filterText,
          business_sector: form.business_sector,
        },
        (res) => {
          if (res.success && res.data.length) {
            setLoading(false);
            setData(res.data);
            setTotalItems(0);
          }
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    }
  }, [type, filterText]);

  // const filterByType = data?.filter((item) => item?.account_type === type);

  // const filtered_data = filterByType?.filter(
  //   (item) =>
  //     item?.name.toLowerCase().includes(filterText.toLowerCase()) ||
  //     item?.taxID.toString().includes(filterText)
  // );

  // const totalTaxPayer = filterByType.length;
  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / form.limit));
  }, [form.limit, totalItems]);

  const gotoPage = (x) => {
    setForm((prevForm) => ({
      ...prevForm,
      offset: x * form.limit,
    }));
  };
  const currentPage = Math.ceil((form.offset + 1) / form.limit);
  return (
    <Card>
      {/* {JSON.stringify(editedTaxPayer)} */}
      {show ? (
        <Row>
          <Col>
            <Button onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </Col>
        </Row>
      ) : null}
      {show ? (
        <UserTransactions taxPayer={taxPayer} viewOnly />
      ) : (
        <>
          <>
            <Modal isOpen={show2} toggle={() => setShow2(false)}>
              <ModalHeader toggle={() => setShow2(false)}>
                Edit Tax Payer
              </ModalHeader>
              <ModalBody>
                <Form>
                  {type === "org" && (
                    <>
                      <center>
                        <ModalTitle
                          style={{
                            backgroundColor: "#4A5073",
                            color: "#f5f8fb",
                          }}
                        >
                          Organizaion Name
                        </ModalTitle>
                      </center>
                      <FormGroup>
                        <Label for="org_name">Organization Name</Label>
                        <Input
                          type="text"
                          id="org_name"
                          value={editedTaxPayer.org_name}
                          onChange={(e) =>
                            setEditedTaxPayer({
                              ...editedTaxPayer,
                              org_name: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="org_name">Office Address</Label>
                        <Input
                          type="text"
                          id="org_name"
                          value={editedTaxPayer.office_address}
                          onChange={(e) =>
                            setEditedTaxPayer({
                              ...editedTaxPayer,
                              office_address: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <center>
                        <ModalTitle
                          style={{
                            backgroundColor: "#4A5073",
                            color: "#f5f8fb",
                          }}
                        >
                          Contact Person
                        </ModalTitle>
                      </center>
                    </>
                  )}
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      id="name"
                      value={editedTaxPayer.name}
                      onChange={(e) =>
                        setEditedTaxPayer({
                          ...editedTaxPayer,
                          name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input
                      type="text"
                      id="phone"
                      value={editedTaxPayer.phone}
                      onChange={(e) =>
                        setEditedTaxPayer({
                          ...editedTaxPayer,
                          phone: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      id="email"
                      value={editedTaxPayer.email}
                      onChange={(e) =>
                        setEditedTaxPayer({
                          ...editedTaxPayer,
                          email: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="address">Address</Label>
                    <Input
                      type="text"
                      id="address"
                      value={editedTaxPayer.address}
                      onChange={(e) =>
                        setEditedTaxPayer({
                          ...editedTaxPayer,
                          address: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={updateTaxPayer}>
                  Save Changes
                </Button>
                <Button color="secondary" onClick={() => setShow2(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </>
          <Card.Body>
            <Row>
              {/* {JSON.stringify(editedTaxPayer)} */}
              <Col md={6}>
                <Card className="bg-white p-2">
                  <CounterWidget
                    color="lightblue"
                    category={`${"Total Tax Payers"}`}
                    count={data[0]?.total_tax_payers || 0}
                    period="Feb 1 - Apr 1"
                    percentage={18.2}
                    icon={faPrint}
                    iconColor="shape-secondary"
                    showChanges={false}
                  />
                </Card>
              </Col>
              <Col md={6}>
                <Card className="bg-white p-2">
                  <CounterWidget
                    color="lightblue"
                    category={`${"Total Organizational Taxpayers"} `}
                    count={data[0]?.total_orgs || 0}
                    period="Feb 1 - Apr 1"
                    percentage={18.2}
                    icon={faPrint}
                    iconColor="shape-secondary"
                    showChanges={false}
                  />
                </Card>
              </Col>
              <Col md={6}>
                <Card className="bg-white p-2">
                  <CounterWidget
                    color="lightblue"
                    category={`${"Total Employees"} `}
                    count={data[0]?.total_staff || 0}
                    period="Feb 1 - Apr 1"
                    percentage={18.2}
                    icon={faPrint}
                    iconColor="shape-secondary"
                    showChanges={false}
                  />
                </Card>
              </Col>
              <Col md={6}>
                <Card className="bg-white p-2">
                  <CounterWidget
                    color="lightblue"
                    category={`${"Total Individual Taxpayers"} `}
                    count={data[0]?.total_individuals || 0}
                    period="Feb 1 - Apr 1"
                    percentage={18.2}
                    icon={faPrint}
                    iconColor="shape-secondary"
                    showChanges={false}
                  />
                </Card>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col md={6} className="mb-2">
                <ButtonGroup aria-label="Account Type">
                  <Button
                    outline
                    className={
                      type === "individual" ? "bg-light text-dark" : ""
                    }
                    onClick={() => {
                      setType("individual");
                      setForm((p) => ({
                        ...p,
                        limit: _form.limit,
                        offset: _form.offset,
                      }));
                    }}
                  >
                    Individual
                  </Button>
                  <Button
                    outline
                    className={type === "org" ? "bg-light text-dark" : ""}
                    onClick={() => {
                      setType("org");
                      setForm((p) => ({
                        ...p,
                        limit: _form.limit,
                        offset: _form.offset,
                      }));
                    }}
                  >
                    Organization
                  </Button>
                </ButtonGroup>
              </Col>
              <Col md={4}>
                {type === "org" ? (
                  <Row>
                    <Col>
                      <SelectInput
                        type="select"
                        name="business_sector"
                        placeholder="Select Business Sector"
                        options={IndurialSectors}
                        value={form.business_sector}
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            business_sector: value,
                            org_type: "",
                            limit: _form.limit,
                            offset: _form.offset,
                          }));
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectInput
                        name="org_type"
                        placeholder="Type of Organization"
                        options={orgCategories?.map((item) => item?.org_type)}
                        value={form.org_type}
                        onChange={({ target: { value } }) => {
                          setForm((p) => ({
                            ...p,
                            business_sector: "",
                            org_type: value,
                            limit: _form.limit,
                            offset: _form.offset,
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col md={2}>
                <Input
                  placeholder="Rows per page"
                  type="select"
                  width={"100px"}
                  value={form.limit}
                  onChange={({ target: { value } }) =>
                    setForm((p) => ({
                      ...p,
                      limit: value,
                      offset: value * (currentPage - 1),
                    }))
                  }
                >
                  {[100, 200, 300, 400, 500].map((lmt, idx) => (
                    <option key={idx} value={lmt}>
                      {lmt} rows
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <div className="mb-2">
              <SearchBar
                filterText={filterText}
                onFilterTextChange={(val) => setFilterText(val)}
                placeholder="Search Tax Payer by Name or Tax ID"
              />
            </div>

            {loading && (
              <div>
                <Spinner />
                Loading...
              </div>
            )}
            {totalItems || currentPage > 2 ? (
              <Row>
                <Col>
                  {currentPage > 2 && (
                    <>
                      <Button size="sm" onClick={() => gotoPage(0)}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => gotoPage(currentPage - 2)}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </Button>
                    </>
                  )}
                  {[...Array(Math.min(10, totalPages))].map((_, index) => {
                    const pageNumber = currentPage + index - Math.floor(10 / 2);
                    return pageNumber > 0 && pageNumber <= totalPages ? (
                      <Button
                        key={index}
                        size="sm"
                        className={
                          currentPage === pageNumber ? "bg-light text-dark" : ""
                        }
                        onClick={() => gotoPage(pageNumber - 1)}
                      >
                        {pageNumber}
                      </Button>
                    ) : null;
                  })}
                  {currentPage < totalPages && (
                    <>
                      <Button size="sm" onClick={() => gotoPage(currentPage)}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => gotoPage(totalPages - 1)}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Button>
                    </>
                  )}
                </Col>
                <Col md={3} className="text-right">
                  Total{" "}
                  {form.business_sector ? "(" + form.business_sector + ")" : ""}{" "}
                  tax payers: <b>{formatNumber1(totalItems)}</b>{" "}
                </Col>
              </Row>
            ) : (
              ""
            )}
            {/* {JSON.stringify(data)} */}
            {/* <Card border="light" className="table-wrapper table-responsive shadow-sm">
<Card.Body className="pt-0"> */}
            <Table
              // style={{ border: "" }}
              // width={"100"}
              // className="user-table align-items-center table-hover"
              bordered
              striped
              responsive
              size="sm"
              // style={{ width: "40%", border: "1px solid black" }}
            >
              <thead>
                <tr>
                  <th className="text-center">ACTION</th>
                  <th className="text-center">Name</th>
                  <th className="text-center">Phone No.</th>
                  <th className="text-center">Address</th>
                  <th className="text-center">Update</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((tax_payer, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          setShow(true);
                          setTaxPayer(tax_payer);
                        }}
                        style={{ marginRight: 5 }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </td>
                    <td className="text-">
                      {tax_payer.org_name?.toUpperCase() ||
                        tax_payer.name?.toUpperCase()}
                      ({tax_payer.taxID})
                    </td>
                    <td className="text-center">
                      {tax_payer.phone || tax_payer.phone}
                    </td>
                    <td>
                      {tax_payer.account_type === "org"
                        ? tax_payer.office_address
                        : tax_payer.address}
                    </td>
                    <td className="text-center">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          // setTaxPayer(tax_payer);
                          setTaxPayer(taxPayer);
                          setEditedTaxPayer({
                            taxID: tax_payer?.taxID,
                            name: tax_payer?.name,
                            phone: tax_payer?.phone,
                            email: tax_payer?.email,
                            address: tax_payer?.address,
                            org_name: tax_payer?.org_name,
                          });
                          setShow2(true);
                        }}
                        style={{ marginRight: 5 }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* </Card.Body>
</Card> */}
          </Card.Body>
        </>
      )}
    </Card>
  );
}

export default TaxPayer;
