import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Row, Col, CardHeader, Table } from 'reactstrap';
import { formatNumber } from '../../utils';
// import GeneralCart from '../components/GeneralCart';
import { CustomButton } from '../../components/UI';
import { _fetchApi, _postApi } from '../../redux/action/api';
import { Button, Modal } from '@themesberg/react-bootstrap';
import PayAssessment from '../generate-receipts/PayAssessment';
import { FaRegFilePdf } from 'react-icons/fa';
import CustomTypeahead from '../../components/UI/CustomTypeahead';
import { useSelector } from 'react-redux';
const FilingWHT = () => {

	const { tax_account } = useSelector(s => s.auth)
	const [taxHeads, setTaxHeads] = useState([])
	const _form = {
		provider_kirmas_id: '',
		description: 'none',
		contract_amount: '',
		start_date: '',
		end_date: '',
		name: '',
		default: 10,
		withholding_amount: '',
		start_date: '',
		end_date: '',
		query_type: 'insert'
	}
	const [rows, setRows] = useState([
		{ ..._form, id: 1, },
	]);
	const [tax_payers, setTaxPayers] = useState([]);
	const [tax_payer, setTaxPayer] = useState('');
	const [form, setForm] = useState(_form)
	const [loading, setLoading] = useState(false)
	const addRow = () => {
		const newRow = {
			..._form,
			id: rows.length + 1,

		};
		setRows([...rows, newRow]);
	};
	const handleInputChange = (id, field, value) => {
		let data = rows.filter((item) => item.id === id);
		let updatedRows = null;
		if (field === 'contract_amount') {
			updatedRows = rows.map((row) => {
				if (row.id === id) {
					return {
						...row, ...data[0],
						[field]: value,
						withholding_amount: parseFloat(row.default * value)
					};
				}
				return row;
			});
		} else {
			updatedRows = rows.map((row) => {
				if (row.id === id) {
					return { ...row, ...data[0], [field]: value };
				}
				return row;
			});
		}
		setRows(updatedRows);
	};

	const handleSelecRow = (row_id, value) => {
		const data = taxHeads.find(x => x.title === value)
		const updatedRows = rows.map((x) => {
			if (row_id === x.id) {
				return {
					...form,
					...x,
					description: data.title,
					default: data.default,
					rev_code: data.economic_code,
					economic_code: data.economic_code,
					mda_code: data.mda_code,
					mda_name: data.mda_name,
					service_category: data.tax_parent_code,
					provider_kirmas_id: form.provider_kirmas_id
				};
			}
			return x;
		});
		setRows(updatedRows);
	}
	const removeRow = (id) => {
		setRows(rows.filter((row) => row.id !== id));
	};

	useEffect(() => {
		if (tax_account.taxID)
			_fetchApi(
				`/kigra-taxes?query_type=get-wht-heads`,
				(resp) => {
					// alert(JSON.stringify(resp))
					if (resp.success) {
						setTaxHeads(resp.result);
						setForm((p) => ({
							...p,
							payer_kirmas_id: tax_account.taxID,
							tax_station: tax_account.tax_station,
						}))
					}
				},
			);
	}, [tax_account.taxID]);

	const [data, setData] = useState([]);
	const [refNo, setRefNo] = useState(null);
	const [showPDF, setShowPDF] = useState(false);

	const handlePreview = () => {
		setData(rows);
	};

	const handleSubmit = () => {
		setLoading(true);
		_postApi(`/assessments/withholding-taxes?query_type=${form.query_type}`,
			{ list: rows },
			(resp) => {
				setLoading(false)
				if (resp.success) {
					setRefNo(resp.ref_no)
				}
			},
			(err) => {
				setLoading(false)
				console.error(err);
			}
		)
	};
	const myRef = useRef(null)

	const getTaxPayers = useCallback(() => {
		if (tax_payer.length > 2) {
			_fetchApi(
				`/users/search-tax-payers?user_id=${tax_payer}&max=50`,
				(resp) => {
					if (resp.success) {
						setTaxPayers(resp.data);
					}
				}
			);
		}
	}, [tax_payer]);

	useEffect(() => {
		getTaxPayers();
	}, [getTaxPayers]);

	return (
		<Row className="p-3">
			{/* {JSON.stringify({ rows })} */}
			<Col md={12} lg={12} className="pt-lg-4 pt-md-2 pt-sm-2">
				<Card>
					{showPDF ? <>
						<Modal size="xl" fullscreen as={Modal.Dialog} centered show={showPDF}>
							<Modal.Header>
								<Modal.Title className="h6">Invoice No.: {refNo}</Modal.Title>
								<Button variant="danger" className="bg-danger text-white" aria-label="Close" onClick={() => setShowPDF(false)}>
									X
								</Button>
							</Modal.Header>
							<Modal.Body>{refNo ? <PayAssessment refNo={refNo} /> : null}</Modal.Body>
						</Modal>
					</> : <>
						<CardHeader className="d-flex justify-content-between">
								<b>Withholding Filing</b>
								{/* <Abdurahan /> */}
							<span className="badge bg-secondary">1</span>
						</CardHeader>
						<CardBody>
							<div>
								<table className='table-bordered table-striped table-responsive p-0 trans'>
									<thead>
										<tr className='p-0 m-0'>
											{/* <th>SN</th> */}
											<th width='25%'> BENEFICIARY NAME/ID</th>
											<th>CONTRACT TYPE</th>
											<th>CONTRACT AMOUNT</th>
											<th>WITHHOLDING</th>
											<th>FROM DATE</th>
											<th>TO DATE</th>
											<th className="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{rows.map((row, index) => (
											<tr key={row.id}>
												<td width='25%' className='p-0 m-0'>

													<CustomTypeahead
														onInputChange={(e) => {
															setTaxPayer(e);
															console.log(e, "TYPED");
														}}
														_ref={myRef}
														labelKey={(e) =>
															`${e.account_type === "org" ? e.org_name : e.name
															}(${e.taxID})}`
														}
														onChange={(e) => {
															setTaxPayer(e);
															if (e && e[0]?.id) {
																setForm((p) => ({
																	...p,
																	provider_kirmas_id: e[0].taxID,
																	phone: e[0].phone,
																	address: e[0].org_type === 'org' ? e[0].office_address : e[0].office_address,
																	tax_payer: e[0].name || e[0].org_name
																}))

															}
														}}
														clearButton
														col={12}
														options={tax_payers}
														placeholder="Search Name | TaxID | Phone No."
													/>
												</td>
												<td>
													<select
														className="form-select"
														value={row.description}
														onChange={(e) => handleSelecRow(row.id, e.target.value)}
													>
														<option>--Select---</option>
														{taxHeads.map((item, x) => (
															<option key={x} value={item.title}>
																{item.title}({item.default * 100}%)
															</option>
														))}
													</select>
												</td>
												<td>
													<input
														type="number"
														className="form-control"
														placeholder="Contract Amount"
														value={row.contract_amount}
														onChange={(e) =>
															handleInputChange(row.id, 'contract_amount', e.target.value)
														}
													/>
												</td><td>
													<input
														type="text"
														name='withholding_amount'
														disable='disable'
														className="form-control"
														placeholder="Withholding Amount"
														value={row.withholding_amount}
													/>
												</td>
												<td>
													<input
														type="date"
														className="form-control"
														value={row.start_date}
														onChange={(e) => handleInputChange(row.id, 'start_date', e.target.value)}
													/>
												</td>
												<td>
													<input
														type="date"
														className="form-control"
														value={row.end_date}
														onChange={(e) => handleInputChange(row.id, 'end_date', e.target.value)}
													/>
												</td>
												<td className="text-center">
													{index !== 0 && (
														<button
															className="btn btn-danger text-white"
															onClick={() => removeRow(row.id)}
														>
															<FontAwesomeIcon icon={faTrash} />
														</button>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<button
								type="button"
								className="rounded-circle bg-dark mt-5 ms-4 mb-2 text-center d-flex justify-content-center align-items-center text-white"
								style={{ width: 34, height: 34, fontSize: 20 }}
								onClick={addRow}
							>
								+
							</button>
							<button type="button" className="btn btn-dark ms-2 mb-2" onClick={handlePreview}>
								Preview
							</button>
							{data.length ? (
								<>
									<Table bordered responsive>
										<thead>
											<tr>
												<th className="text-center">CONTRACT TYPE</th>
												<th className="text-center">CONTRACT AMOUNT</th>
												<th className="text-center">PERIOD</th>
												<th className="text-center">WITHHOLDING AMOUNT</th>
											</tr>
										</thead>
										<tbody>
											{data.map((item) => (
												<tr>
													<td className="text-">
														{item.description}({item.default * 100}%)
													</td>
													<td className="text-right">{formatNumber(item.contract_amount)}</td>
													<td className="text-center">
														{moment(item.start_date).format('YYYY-MM-DD')} to{' '}
														{moment(item.end_date).format('YYYY-MM-DD')}
													</td>
													<td className="text-right">{formatNumber(item.withholding_amount)}</td>
												</tr>
											))}
										</tbody>
									</Table>
									<center>
										{refNo ? <Button
											className="w-25 "
											style={{
												backgroundColor: "#262B40",
												color: "white",
												border: "none",
												boxShadow: "none",
												float: "right",
											}}
											onClick={() => setShowPDF(true)}
										>
											<FaRegFilePdf /> Proceed to payment
										</Button>
											: <CustomButton onClick={handleSubmit} loading={loading}> Submit</CustomButton>}
									</center>
								</>
							) : null}
						</CardBody>
					</>}
				</Card>
			</Col>
		</Row >
	);
};

export default FilingWHT;
