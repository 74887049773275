import { LOADING_SIGNUP, AUTH, LOADING_LOGIN, AUTH_ERROR, LOGOUT, LOADING_APP, ERRORS, TAX_ACCOUNT } from './type';
import { _fetchApi, apiURL } from './api';
import { Routes } from '../../routes';
// import { useHistory } from 'react-router-dom';

export const navigateBasedOnAccess = (access, history) => {
    // if (access && access.length) {
    if (access?.split(',').includes('Dashboard')) {
            return history.push(Routes.DashboardOverview.path);
    }else{            
        return history.push(Routes.PayInvoice.path);
    }
};

export function signup(objs = {}, success = (f) => f, error = (f) => f) {
    return (dispatch) => {
        dispatch({ type: LOADING_SIGNUP });
        fetch(`${apiURL}/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objs),
        })
            .then((raw) => raw.json())
            .then((datas) => {
                const { user, token } = datas;
                dispatch({ type: LOADING_SIGNUP });
                // alert(JSON.stringify(datas));
                if (datas.success) {
                    console.log(objs);

                    success(datas);
                    // dispatch(
                    //   login(
                    //     { username: objs.email, password: objs.password },
                    //     (data) => {
                    //       success(datas);
                    //       console.log(datas, "DDDDDD", objs);
                    //       dispatch({ type: AUTH, payload: user[0] });
                    //       if (token) {
                    //         console.log(token);
                    //         localStorage.setItem("@@auth_token", token);
                    //       }
                    //     },
                    //     (err) => {
                    //       error(err);
                    //       if (err)
                    //         dispatch({
                    //           type: AUTH_ERROR,
                    //           payload: err,
                    //         });
                    //     }
                    //   )
                    // );
                } else {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: { msg: 'Server error, try again.' },
                    });
                    error(datas);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_SIGNUP });
                console.log({ err });
                error(err);
            });
    };
}

export function expressSignup(objs = {}, success = (f) => f, error = (f) => f) {
    return (dispatch) => {
        dispatch({ type: LOADING_SIGNUP });
        fetch(`${apiURL}/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objs),
        })
            .then((raw) => raw.json())
            .then((datas) => {
                const { user, token, taxID } = datas;
                dispatch({ type: LOADING_SIGNUP });
                // alert(JSON.stringify(datas));
                if (datas.success) {
                    console.log(objs);
                    success(datas);
                } else {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: { msg: 'Server error, try again.' },
                    });
                    error(datas);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_SIGNUP });
                console.log({ err });
                error(err);
            });
    };
}
export function login({ username, password, history }, success, error) {
    return (dispatch) => {
        dispatch({ type: LOADING_LOGIN });
        fetch(`${apiURL}/sign_in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
            .then((raw) => raw.json())
            .then((data) => {
                // console.log(data, "KKDKDKDDK");
                dispatch({ type: LOADING_LOGIN });
                // console.log(data);
                if (data.success) {
                    const { token } = data;
                    // console.log(token);
                    if (token) {
                        localStorage.setItem('@@auth_token', token);
                    }

                    getUserProfile(token)
                        .then((userData) => {
                            // console.log(userData, data, "KKDKDKDDK");
                            if (data.success) {
                                /**
                                 * Token is valid
                                 * navigate user to dashboard */
                                // callback();
                                dispatch({ type: LOADING_APP });
                                const { user, tax_accounts } = userData;
                                if (user.role === 'user') {
                                    dispatch({
                                        type: AUTH,
                                        payload: {
                                            user,
                                            tax_account: tax_accounts ? tax_accounts[0] : [],
                                            tax_accounts: tax_accounts ? tax_accounts : [],
                                        },
                                    });
                                    success(data);
                                } else {
                                    dispatch({
                                        type: AUTH,
                                        payload: {
                                            user,
                                        },
                                    });
                                    success(data);
                                }

                                // history.push("/selection");
                            } else {
                                // callback();
                                localStorage.removeItem('@@auth_token');
                                // history.push("/");

                                dispatch(logout(history));
                            }
                        })
                        .catch((error) => {
                            dispatch(logout(history));
                            dispatch({
                                type: ERRORS,
                                payload: { msg: 'Authentication failed', error },
                            });
                        });
                } else {
                    dispatch({ type: ERRORS, payload: data.msg });
                    error(data.error);
                    // console.log(data);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_LOGIN });
                // console.log(err)
            });
    };
}

export async function getUserProfile(_token) {
    try {
        let response = await fetch(`${apiURL}/verify-token`, {
            method: 'GET',
            headers: {
                authorization: _token,
            },
        });
        let data = await response.json();
        return data;
    } catch (error) {
        if (error.status === 401) {
            logout();
            window.location.reload();
        }
        return error;
    }
}

export function getTaxPayerInfo(tax_payer_id, cb = (f) => f, err = (f) => f) {
    return (dispatch) => {
        _fetchApi(
            `/users/get-tax-payer-info?user_id=${tax_payer_id}`,
            (resp) => {
                if (resp.success) {
                    dispatch({
                        type: TAX_ACCOUNT,
                        payload: resp.data,
                    });
                    cb();
                }
            },
            (error) => {
                console.error(error);
                err();
            },
        );
    };
}

export function init(history, success = (f) => f, error = (f) => f) {
    return (dispatch) => {
        let _token = localStorage.getItem('@@auth_token');
        if (_token) {
            /**
             * Token present
             * verifyToken */
            getUserProfile(_token)
                .then((data) => {
                    if (data.success) {
                        const { user, tax_accounts } = data;
                        if (user && user.role !== 'user') {
                            dispatch({
                                type: AUTH,
                                payload: {
                                    user,
                                },
                            });
                            // navigateBasedOnAccess(user.accessTo, history);
                            success();
                        } else {
                            console.log(tax_accounts);
                            dispatch({
                                type: AUTH,
                                payload: {
                                    user,
                                    tax_account: tax_accounts[0] || [],
                                    tax_accounts: tax_accounts ? tax_accounts : [],
                                },
                            });
                            success();
                        }
                    } else {
                        // callback();
                        localStorage.removeItem('@@auth_token');
                        dispatch(logout(history));
                        success();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: ERRORS,
                        payload: { msg: 'Authentication failed', error },
                    });
                    dispatch(logout(history));
                    error();
                });
        } else {
            /**
             * No token found
             * navigate user to auth page
             */
            dispatch(logout(history));
            dispatch({ type: ERRORS, payload: { msg: 'Authentication failed' } });
            error();
        }
    };
}

export function logout(history) {
    return (dispatch) => {
        if (history.location.pathname === '/sign-up'||history.location.pathname === '/login') {
        } else {
            history.push('/');
        }
        localStorage.removeItem('@@auth_token');
        dispatch({ type: LOGOUT });
    };
}
