import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { InterswitchPay } from 'react-interswitch';
import { _fetchApi, _postApi } from '../../redux/action/api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../routes';

const PaymentButton = ({
    addStyle = {},
    merchantCode = '60969',
    // payItemID = "60969",
    amount = 0,
    label = 'Confirm Payment',
    email = '',
    sector = '',
    data = [],
    taxID = '',
    name = '',
    phone = '',
    reference_no = '',
    init = (f) => f,
    toggle = (f) => f,
    setPayModal = (f) => f,
    user = {},
}) => {
    let [refNo, setRefNo] = useState(null);
    let payItemID = null;
    const history = useHistory();
    const location = useLocation();
    const [checker, setChecker] = useState(false);
    useEffect(() => {
        if (checker) {
            history.push(`/online-payment?reference_number=${refNo || reference_no}`);
        }
    }, [checker]);
    if (!reference_no && data.length) {
        init((ref) => {
            setRefNo(ref);
        });
    }
    const handleResponse = (response) => {
        if (response.desc === 'Approved by Financial Institution') {
            fetch(
                `https://webpay.interswitchng.com/collections/api/v1/gettransaction.json?merchantcode=MX${merchantCode}&transactionreference=${
                    refNo || reference_no
                }&amount=${amount * 100}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            )
                .then((raw) => raw.json())
                .then((resp) => {
                    if (resp) {
                        console.log(resp);
                        console.log('resp');
                        if (resp.ResponseCode === '00') {
                            _postApi(
                                '/inter-response',
                                { ...resp, ...response },
                                (res) => {
                                    _fetchApi(
                                        `/users/get-org-staff?org_id=${user.taxID}`,
                                        (resp) => {
                                            if (resp.success) {
                                                let data = [];
                                                console.log(resp.data)
                                                resp.data.forEach((item) => {
                                                    data.push({
                                                        date_issued:moment().format("YYYY-MM-DD"),
                                                        tax_payer:item.name,
                                                        tin:item.tin,
                                                        tcc_ref:moment().format("YYYYMMDDhhmmss"),
                                                        tax_file_no:10,
                                                        taxID:item.taxID,
                                                        income_source:"Employment",
                                                        year:moment().format("YYYY"),
                                                        first_amount:0,
                                                        second_amount:0,
                                                        third_amount:item.payable,
                                                        first_income:0,
                                                        second_income:0,
                                                        third_income:item.basic_salary*12,
                                                        status:"pending",
                                                        org_name:"",
                                                        org_id:taxID,
                                                        type:"Formal Business",
                                                    });
                                                });
                                                _postApi('/assessments/tcc-auto-request',
                                                {data},
                                                (res) => {
                                                  if (res.success) {
                                                    toast.success('Submitted')
                                                  }
                                                },
                                                (er) => {
                                                  console.error(er);
                                                  toast.error('Not worgin')
                                                }
                                              )
                                            }
                                        },
                                    );
                                    setChecker(true);
                                    if (res.successs) {
                                        setChecker(true);
                                    }
                                },
                                (err) => {
                                    console.log(err);
                                    toast.error('Error occured while saving the payment update ');
                                },
                            );
                        }
                    } else {
                        console.log('Empty response');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Payment aborted!');
                });
        } else {
            toast.error('Payment aborted!');
            console.error(response);
        }
    };
    switch (sector) {
        case 'VEHICLES':
            payItemID = '7035882';
            break;
        case 'LGA':
            payItemID = '4351942';
            break;
        case 'LAND':
            payItemID = '6762266';
            break;
        case 'NON TAX':
            payItemID = '6160982';
            break;
        case 'TAX':
            payItemID = '5775505';
            break;

        default:
            break;
    }
    let new_amount = amount.toString().split('.')[0] ;
    let input_amount = new_amount*100 ;
    let input_kobos = new_amount?.toString().split('.')[1]||0;
    const paymentParameters = {
        merchantCode: `MX${merchantCode}`,
        payItemID,
        customerEmail: email,
        redirectURL: `https://kirmas.kn.gov.ng${Routes.OnlinePayment.path}?reference_number=${refNo || reference_no}`,
        text: ` ${label}(NGN${formatNumber(amount)})`,
        mode: 'LIVE', //"TEST",
        payRef: 'WEB',
        customerName: name.replace("'", '&#x27;'),
        customerID: taxID?.toString(),
        currency: '566',
        transactionReference: reference_no,
        amount: `${input_amount}.${input_kobos}`,
        style: {
            // width: '200px',
            height: '40px',
            border: 'none',
            color: '#fff',
            backgroundColor: '#262B40',
            padding: '7px',
            width: '100%',
            borderRadius: '10px',
            ...addStyle,
        },
        callback: (response) => {
            handleResponse(response);
            console.log('response: ', response);
        },
    };
    return (
        <>
            <InterswitchPay {...paymentParameters} />
        </>
    );
};

// {"id":18878,"user_id":null,"name":"Muhammad Fahad Ado","username":null,"email":null,"office_email":null,"role":null,"bvn":null,"tin":"1073702019","nin":1073702019,"org_tin":null,"org_type":null,"taxID":70895,"org_name":"SILVER WING LIMITED","rc":null,"account_type":"individual","phone":"","office_phone":null,"state":null,"lga":null,"address":null,"office_address":null,"parent_tax_id":"124","basic_salary":100000,"other_allowances":0,"gross_salary":1200000,"nhis":60000,"nhf":25000,"pension":96000,"payable":56280,"taxable_income":615200,"cgi":1019000,"cra":403800,"status":"active","accessTo":null,"createdAt":"2024-05-23T15:28:18.000Z","updatedAt":"2024-05-23T15:28:18.000Z","ward":null},

export default PaymentButton;
