import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader,
    Button,
    Table,
    Badge,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Row,
    Spinner,
    Container,
    Col,
    Alert,
} from 'reactstrap';
import { _fetchApi, _postApi } from '../../redux/action/api';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Checkbox from '../../components/UI/CheckBox';
import { CustomButton } from '../../components/UI';
import moment from 'moment';
import { formatNumber, formatNumber1 } from '../../utils';
import GeneralCart from '../components/GeneralCart';
import CustomModal from '../../components/UI/CustomModal';
import useIsPhoneSize from '../../utils/useIsPhoneSize';
import { Image } from '@themesberg/react-bootstrap';
import { Routes } from '../../routes';
import PaymentButton from '../payments/PaymentButton';
// import { useHistory } from "react-router-dom/cjs/react-router-dom";

function PendingTax() {
    const { tax_account, user } = useSelector((s) => s.auth);
    const [pendingTax, setPendingTax] = useState([]);
    const [selectedTax, setSelectedTax] = useState({});
    const navigate = useHistory();

    useEffect(() => {
        _fetchApi(
            `/users/get-pending-tax?taxID=${tax_account?.taxID}`,
            (resp) => {
                if (resp.success) {
                    let arr = [];
                    resp.results.forEach((item) => {
                        arr.push({ ...item, check: false, month: '' });
                    });
                    setPendingTax(arr);
                }
            },
            (err) => {
                console.error(err);
                toast.error('Errorr occured!');
            },
        );
    }, [tax_account?.taxID]);

    const handleChange = (index) => {
        let arr = [];
        pendingTax.forEach((item, i) => {
            if (i === index) {
                arr.push({ ...item, check: !item.check, month: moment(item.date_from).format('MMMM') });
            } else {
                arr.push(item);
            }
        });
        setPendingTax(arr);
    };
    let months = pendingTax.map((item) => item.month).filter((item) => item !== '');
    let filterData = [selectedTax];
    const [isOpen, setIsOpen] = useState(false);
    const toggle = (type) => {
        setIsOpen(!isOpen);
    };
    const isPhoneSize = useIsPhoneSize();
    const total = [selectedTax].reduce((it, id) => it + parseFloat(id?.payable), 0);
    const [accordTogle, setAccordTogle] = useState('');
    const accordTogleFun = (id) => {
        if (accordTogle === id) {
            setAccordTogle();
        } else {
            setAccordTogle(id);
        }
    };
    const [loading, setLoading] = useState(false);
    const [refNo, setRefNo] = useState('');
    const payeEntries = (ref_no, org_id, data = []) => {
        _postApi(
            `/assessments/staff-auto-update?ref_no=${ref_no}&org_id=${org_id}`,
            { data },
            (resp) => {
                if (resp.success) {
                    toast.success('Done');
                }
            },
            (e) => {
                console.error(e);
            },
        );
    };
    const handleSubmit = (transaction_type = 'invoice') => {
        const data = [selectedTax];
        const tax_list = [];
        if (data.length) {
            data.filter((item) => item.check === true).forEach((item) => {
                tax_list.push({
                    ...item,
                    mda_code: '22000800100',
                    mda_name: 'Kano State Internal Revenue Service',
                    transaction_type: 'payment',
                    description: item.tax_name,
                    rev_code: '12020132',
                    amount: item.tax_fee,
                    service_category: 'Pay As You earn',
                    item_code: item.id,
                    from: moment().format(item.date_from),
                    to: moment().format(item.date_to),
                    amount: item.payable / 12,
                    tax_fee: item.payable / 12,
                    sector: 'TAX',
                    phone: tax_account.office_phone || tax_account.phone,
                });
            });
            tax_list.push({
                mda_code: '22000800100',
                mda_name: 'Kano State Internal Revenue Service',
                tax_code: data[0]?.tax_code,
                sector: 'TAX',
                tax_parent_code: data[0]?.tax_parent_code,
                amount: total,
                total: total,
                description: 'Pay As You Earn',
                transaction_type: 'invoice',
                rev_code: '12020132' || '',
                service_category: 'Pay As You earn',
                item_code: data[0].id || '',
                mda_code: data[0]?.mda_code || '',
                mda_name: data[0]?.mda_name || '',
                department: data[0]?.department,
                phone: data[0].phone ? data[0].phone : tax_account.office_phone || tax_account.phone,
                tax_fee: total,
            });
            const final_data = {
                total: total,
                user_id: tax_account.taxID,
                nin: tax_account.nin,
                nin_id: tax_account.nin_id,
                agent_id: user.role === 'user' ? '' : user.id,
                tax_list: tax_list,
                tax_station: data[0].tax_station,
                transaction_type,
                transaction_date: moment().format('YYYY-MM-DD'),
                start_date: moment(data[0].from).format('YYYY-MM-DD'),
                end_date: moment(data[0].to).format('YYYY-MM-DD'),
                tax_payer: tax_account.org_name || tax_account.name,
                phone: tax_list[0].phone,
                invoice_status: data[0]?.invoice_status || '',
                tax_fee: total,
            };
            console.log(tax_list, tax_list);
            setLoading(true);
            _postApi(
                '/transactions/execute',
                final_data,
                (resp) => {
                    if (resp.success) {
                        setIsOpen(true);
                        setRefNo(resp.data[0].ref_no);
                        _fetchApi(
                            `/transactions/update-paye-entries?ref_no=${resp.data[0].ref_no}&assessment_id=${selectedTax.assessment_id}`,
                            (resp) => {
                                console.log(resp);
                                if (resp.success) {
                                    let data = [];
                                    console.log(resp.data);
                                    togglePrint();
                                    // resp.data.forEach((item) => {
                                    //     data.push({
                                    //         date_issued: moment().format('YYYY-MM-DD'),
                                    //         tax_payer: item.name,
                                    //         tin: item.tin,
                                    //         tcc_ref: moment().format('YYYYMMDDhhmmss'),
                                    //         tax_file_no: 10,
                                    //         taxID: item.taxID,
                                    //         income_source: 'Employment',
                                    //         year: moment().subtract(1, 'year').format('YYYY'),
                                    //         first_amount: 0,
                                    //         second_amount: 0,
                                    //         third_amount: item.payable,
                                    //         first_income: 0,
                                    //         second_income: 0,
                                    //         third_income: item.basic_salary * 12,
                                    //         status: 'initiated',
                                    //         org_name: '',
                                    //         org_id: user.taxID,
                                    //         type: 'Formal Business',
                                    //     });
                                    // });
                                    // _postApi(
                                    //     '/transactions/update-paye-entries?',
                                    //     { data },
                                    //     (res) => {
                                    //         if (res.success) {
                                    //             toast.success('Submitted');
                                    //         }
                                    //     },
                                    //     (er) => {
                                    //         console.error(er);
                                    //         toast.error('Not working');
                                    //     },
                                    // );
                                }
                            },
                        );
                        setLoading(false);
                    } else {
                        toast.error('Error');
                        setLoading(false);
                    }
                },
                (e) => {
                    console.error('Error', e);
                },
            );
        }
    };
    const [isPrint, setPrint] = useState(false);
    const togglePrint = () => {
        setPrint(!isPrint);
    };
    const handleCallBack = () => { };
    return (
        <Card>
            {/* {JSON.stringify(pendingTax)} */}
            <CardHeader className="d-flex justify-content-between">
                <h4 className="mt-2">
                    {' '}
                    {tax_account?.org_name?.toUpperCase() || tax_account?.name?.toUpperCase()} ({tax_account?.taxID})
                </h4>
            </CardHeader>
            {/* <CardHeader>Find Task arrange below</CardHeader> */}
            <CardBody className="">
                {/* <div className="mb-2">
                    <CustomButton
                        disabled={!filterData.length}
                        onClick={() => {
                            toggle();
                        }}
                    >
                        Process
                    </CustomButton>
                </div> */}
                {/* {JSON.stringify(selectedTax)} */}
                <Table className="table table-bordered" responsive>
                    <thead>
                        <tr>
                            <th scope="col">Action</th>
                            <th scope="col">Month</th>
                            {/* <th scope="col">Period Covered</th> */}
                            <th scope="col">No. of Staff</th>
                            <th scope="col">Gross</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Interest</th>
                            <th scope="col">Panelty</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingTax?.map((item, index) => (
                            <tr key={index}>
                                <th className="text-center">
                                    {/* <Checkbox
                                        name={index}
                                        checked={item.check}
                                        onChange={() => {
                                            handleChange(index);
                                        }}
                                        value={item.check}
                                    /> */}
                                    <CustomButton
                                        onClick={() => {
                                            setSelectedTax(item);
                                            toggle();
                                        }}
                                        size="sm"
                                    >
                                        Process
                                    </CustomButton>
                                </th>
                                <td className="text-center">{moment(item.date_from).format('MMMM')}</td>
                                {/* <td>
                                    {moment(item.date_from).format('YYYY-MM-DD')} to{' '}
                                    {moment(item.date_to).format('YYYY-MM-DD')}
                                </td> */}
                                <td className="text-center">{formatNumber1(item.numOfStaff)}</td>
                                <td className="text-right"> {formatNumber(item.gross)}</td>
                                <td className="text-right"> {formatNumber(item.payable)}</td>
                                <td className="text-right"> {formatNumber()}</td>
                                <td className="text-right">{formatNumber()}</td>
                                <td>
                                    <Badge>{item.status}</Badge>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {!pendingTax.length ? (
                    <div>
                        <Alert className="text-center">No Pending Liability Added Yet!</Alert>
                    </div>
                ) : null}
            </CardBody>
            <CustomModal
                logo={
                    <center className="p-0 text-center">
                        <Image
                            src={require('../../assets/img/kigra.jpg')}
                            className="img-fluid"
                            alt="Logo"
                            style={{
                                borderRadius: '64px',
                                height: isPhoneSize ? '10%' : '20%',
                                width: isPhoneSize ? '10%' : '20%',
                            }}
                        />
                    </center>
                }
                size="lg"
                toggle={toggle}
                isOpen={isOpen}
            >
                {/* {JSON.stringify(selected_taxes)} */}

                {/* <GeneralCart
                    form={{ from: '', to: '' }}
                    data={[
                        {
                            // ...taxHead,
                            title: 'Pay As You Earn',
                            tax_parent_code: 'Pay As You Earn',
                            amount: formatNumber(total),
                            tax_fee: total,
                            rev_code: '12010106',
                            item_code: '12010106',
                            economic_code: '12010106',
                            description: 'Pay As You Earn',
                            transaction_type: 'invoice',
                            service_category: 'Pay As You Earn',
                            page_title: 'TAX PAYMENTS',
                            trx_type: 'invoice',
                            checked: true,
                            from: '',
                            to: '',
                        },
                    ]}
                    with_staff={true}
                    setModal={setIsOpen}
                    handleTaxFeeChecked={() => {}}
                > */}
                {/* <Accordion flush open={accordTogle} toggle={accordTogleFun}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">File history</AccordionHeader>
                            <AccordionBody accordionId="1"> */}
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3>TAX PAYMENTS</h3>
                    <h5>Payment for: Pay As You Earn</h5>
                    <h5>Tax Payer: {tax_account.org_name || tax_account.name}</h5>
                    <h5>Address: {tax_account?.office_address || tax_account?.address}</h5>
                </div>
                <div className="d-flex justify-content-between">
                    <h6>({filterData.length}) Items selected</h6>
                    <h6>Total: {formatNumber(total)}</h6>
                </div>
                <Table className="table-zebra" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">
                                Tax Name
                            </th>
                            <th scope="col" className="text-center">
                                Month
                            </th>
                            <th scope="col" className="text-center">
                                No. of Staff
                            </th>
                            <th scope="col" className="text-center">
                                Gross
                            </th>
                            <th scope="col" className="text-center">
                                Amount
                            </th>
                            <th scope="col" className="text-center">
                                Interest
                            </th>
                            <th scope="col" className="text-center">
                                Panelty
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[selectedTax]?.map((item, index) => (
                            <tr key={index}>
                                <td className="text-">{item.tax_name}</td>
                                <td className="text-center">{moment(item.date_from).format('MMMM')}</td>
                                <td className="text-center">{formatNumber1(item.numOfStaff)}</td>
                                <td className="text-right"> {formatNumber(item.gross)}</td>
                                <td className="text-right"> {formatNumber(item.payable)}</td>
                                <td className="text-right">{formatNumber()}</td>
                                <td className="text-right">{formatNumber()}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row>
                    <Button
                        color="warning"
                        className="mr-2"
                        block
                        onClick={() => {
                            handleSubmit('invoice');
                        }}
                        disabled={loading}
                    >
                        {loading ? <Spinner size="sm" /> : null}
                        Save
                    </Button>
                </Row>
            </CustomModal>
            <CustomModal size="lg" toggle={togglePrint} isOpen={isPrint}>
                <Container className="text-center">
                    <h3>Your Invoice ref. is: {refNo}</h3>
                    <Row className=" row d-flex ">
                        <Col>
                            <CustomButton
                                // className="btn-link"
                                onClick={() => {
                                    navigate.push(`${Routes.paymentInvoice.path}?ref_no=${refNo}`);
                                }}
                            >
                                Generate Invoice
                            </CustomButton>
                        </Col>
                        <Col>
                            <PaymentButton
                                onMouseOver={() => { }}
                                label="Pay Now"
                                addStyle={{ with: '100%', marginTop: 10 }}
                                amount={total}
                                reference_no={refNo}
                                email={tax_account.org_email || tax_account.email}
                                name={tax_account.org_name || tax_account.name}
                                taxID={tax_account.taxID}
                                phone={tax_account.office_phone || tax_account.phone}
                                data={[selectedTax]}
                                sector={'TAX'}
                                user={user}
                                callback={handleCallBack}
                            // setPayModal={setPayModal}
                            // toggle={toggle}
                            />
                        </Col>
                    </Row>
                </Container>
            </CustomModal>
        </Card>
    );
}
export default PendingTax;
